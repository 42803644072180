import React from "react"

import styles from "./main.module.css"

export default function Main({ children }) {
  return (
    <main role="main" className={styles.main}>
      {children}
    </main>
  )
}
